'use client';

import Image from 'next/image';
import Button from '../components/Button/Button';
import GroundsureIoLogo from '@/src/components/Logos/GroundsureIoLogo/GroundsureIoLogoFull';

export default function NotFound() {
  function redirectToHomePage() {
    window.location.href = '/';
  }
  return (
    <div className="bg-[#f3f8f3] min-h-screen flex flex-col items-center justify-center p-8">
      <GroundsureIoLogo className="mb-14" />

      <div className="px-8 lg:p-0">
        <Image className="mb-16" src="/assets/icons/not-found.svg" alt="Page not found image" width={730} height={403} priority />
      </div>

      <h1 className="text-blue-100 text-3xl font-museo-700 text-center mb-2">Oops, you went off the grid!</h1>
      <p className="text-blue-100 font-museo-300 text-center mb-8">Click below to explore more of what Groundsure.io has to offer.</p>

      <Button
        onClick={() => redirectToHomePage()}
        className="max-w-[224px]"
        type="button"
        text="Take me to Groundsure.io"
        size="sm"
        icon={<Image src="/assets/icons/border-right-arrow.svg" alt="To home page" width="16" height="16" />}
      />

    </div>
  );
}
